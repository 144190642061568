@font-face {
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/icomoon.eot?xodj8p");
  src: url("./fonts/icomoon.eot?xodj8p#iefix") format("embedded-opentype"),
    url("./fonts/icomoon.ttf?xodj8p") format("truetype"),
    url("./fonts/icomoon.woff?xodj8p") format("woff"),
    url("./fonts/icomoon.svg?xodj8p#icomoon") format("svg");
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.icon-close::before {
  content: "\e900";
}

.icon-menu::before {
  content: "\e901";
}
