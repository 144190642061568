.real-time_container_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .real-time_global_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .real-time_container_filter {
    width: 70%;
  }

  .real-time_container_filter_button {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    align-self: baseline;
    color: var(--text);
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 22px;
    width: 100%;

    .container_filter_button {
      display: flex;
      justify-content: flex-end;
      align-items: baseline;

      .block_count_users {
        margin-right: 10px;
        &__count {
          margin-left: 5px;
        }
      }
    }

    .filter_button {
      align-items: flex-end;
      color: var(--text-primary);
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      line-height: normal;
      margin-left: 11px;
      position: relative;
      user-select: none;
      margin-bottom: 10px;
      max-width: 151px;

      & > i {
        margin-left: 5px;
      }
    }

    .filter {
      &__dropdown {
        position: absolute;
        top: 120%;
        right: 0;
        border-radius: 10px;
        background-color: var(--background-box-time-day);
        min-width: 160px;
        z-index: 100;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        box-shadow: 0 4px 34px rgba(0, 0, 0, 0.25);
      }

      &__item {
        color: var(--color-resource-plaaning);
        padding: 15px;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        text-align: left;
        background: transparent;
        transition: all 300ms;

        &:hover {
          background: var(--bg-hover-invoices-list-item);
        }
      }
    }
  }
  .projects_user_data {
    border-bottom: var(--border-bottom-reports-projects);
  }

  .real-time_container_real-time {
    align-self: baseline;
    color: var(--text);
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 22px;
    width: 70%;

    &--full {
      width: 100%;
    }

    .projects_data {
      .projects_user {
        border-bottom: var(--border-bottom-reports-projects);

        .projects_user_data {
          align-items: center;
          display: flex;
          justify-content: space-between;
          min-height: 26px;
          padding: 12px;
          border-bottom: var(--border-bottom-reports-projects);
        }
        .projects_user_data-user {
          align-items: center;
          display: flex;
          justify-content: space-between;
          padding: 5px 0px;
          border-bottom: var(--border-bottom-reports-projects);

          &__block {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 35%;
            display: flex;
          }

          &__avatar {
            display: inline-flex;
            position: relative;
          }
          .project-user-info {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            display: flex;
            flex-direction: column;
            .project-name {
              overflow: hidden;
              text-overflow: ellipsis;
              width: 72%;
              padding-bottom: 2px;
            }
            &__role {
              font-weight: 500;
              &__member {
                color: #00b173;
              }

              &__owner {
                color: #ffae00;
              }

              &__admin {
                color: #f04747;
              }

              &__invoices-manager {
                color: #ff5e00;
              }
            }
            &__tags {
              display: flex;
              grid-gap: 10px;
              gap: 10px;
              flex-wrap: wrap;
            }
            &__tag {
              background-color: var(--bg-tag);
              border-radius: 100px;
              color: var(--text);
              padding: 2px 10px;
              line-height: 14px;
              font-size: 14px;
              font-weight: 500;
            }
          }

          &__task-info {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 45%;
            display: flex;
            flex-direction: column;

            &__issue {
              font-weight: 600;
              padding-bottom: 2px;
              .issue_popup {
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            &__circle {
              border-radius: 50%;
              display: inline-block;
              margin: 0 0.5rem 0 0;
              min-height: 1.2rem;
              min-width: 1.2rem;
            }
          }

          &__timer-info {
            font-weight: 500;
            width: 20%;
            display: flex;
            flex-direction: column;
            text-align: end;

            &__active {
              color: #00b173;
            }

            &__no-active {
              color: #f04747;
            }

            &__last-active {
              padding-top: 2px;
              color: var(--color-resource-plaaning);
            }
          }
          .avatar-small {
            width: 42px;
            height: 42px;
          }
        }

        a .projects_user_data:hover {
          background-color: var(--background-box-time-day);
        }
      }
    }
    .cross_team {
      border-bottom: var(--border-bottom-reports-projects);
      width: 100%;
      .cross_team_user {
        align-items: center;
        display: flex;
        min-height: 26px;
        padding: 11px 0;
        width: 100%;
        &__block {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 50%;
          display: flex;

          @media (min-width: 1445px) {
            width: 42%;
          }
        }

        &__avatar {
          display: inline-flex;
          position: relative;
        }
        .cross_team-info {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          display: flex;
          flex-direction: column;
          .cross_team-user {
            display: flex;
            width: 100%;
            padding-bottom: 2px;
            &__name {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 180px;
              font-size: 14px;
              font-weight: 600;
              line-height: 19px;
              margin-bottom: 8px;
            }
            &__status {
              padding-left: 23px;
            }
            &__project {
              padding-left: 16px;
              &__name {
                font-size: 14px;
                font-weight: 600;
                line-height: 19px;
              }
            }
          }
          &__role {
            font-weight: 500;
            &__member {
              color: #00b173;
            }

            &__owner {
              color: #ffae00;
            }

            &__admin {
              color: #f04747;
            }

            &__invoices-manager {
              color: #ff5e00;
            }
          }
          .cross_team-info-tags {
            display: flex;
            align-items: center;
            &__icon {
              position: absolute;
              width: 18px;
              height: 100%;
              path {
                height: 100%;
                stroke: var(--text);
              }

              &--left {
                height: 100%;
                transform: rotate(90deg);
              }

              &--right {
                transform: rotate(-90deg);
                height: 100%;
                right: 0;
              }
            }
            &__tags {
              width: 100%;
              display: flex;
              overflow: hidden;
            }
            &__tag {
              height: 18px;
              background-color: var(--bg-tag);
              border-radius: 100px;
              color: var(--text);
              padding: 2px 10px;
              margin: 0 3.5px;
              line-height: 14px;
              font-size: 10px;
              font-weight: 600;
              transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
            }
          }
        }

        &__task-info {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 50%;
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
          padding-left: 30px;
          padding-right: 5px;

          &__issue {
            margin-top: 8px;
            padding-bottom: 2px;
            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
            .issue_popup {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &__circle {
            border-radius: 50%;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin: 0 0.5rem 0 0;
          }
        }

        &__timer-info {
          width: 20%;
          display: flex;
          flex-direction: column;
          text-align: end;
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;

          &__active {
            color: #00b173;
            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
          }

          &__no-active {
            color: #f04747;
            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
          }

          &__last-active {
            padding-top: 2px;
            color: var(--color-resource-plaaning);
            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
          }
        }
        .avatar-small {
          width: 42px;
          height: 42px;
        }
      }
    }
  }

  .chart {
    margin-left: 5%;
    margin-top: 24px;
    position: relative;
    .time-ancor {
      position: relative;
      canvas {
        position: relative;
        z-index: 2;
      }

      .total_time_tasks {
        color: var(--text);
        font-family: 'Open Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: bold;
        left: 50%;
        line-height: normal;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .MuiSvgIcon-root {
    color: var(--text) !important;
  }
}

.reports_carousel {
  height: 19px;
  display: flex;
}

.reports_carousel > [data-arrow='right'] {
  background: linear-gradient(270deg, #fafafc 50%, rgba(255, 255, 255, 0));
  right: 0;
  opacity: 1;
  position: absolute;
  cursor: pointer;
  width: 45px;
  height: 18px;
  margin-bottom: auto;
  margin-top: auto;
}

.reports_carousel > [data-arrow='left'] {
  background: linear-gradient(90deg, #fafafc 10%, rgba(255, 255, 255, 0));
  left: -6px;
  opacity: 1;
  width: 45px;
  height: 20px;
  position: absolute;
  cursor: pointer;
  margin-bottom: auto;
  margin-top: auto;
}

.styles-module_sliderBase__swkx1 {
  width: 180px !important;
}
.styles-module_slider__o0fqa {
  height: 18px;
  width: 155px !important;
}

[data-theme='dark'] .reports_carousel > [data-arrow='right'] {
  background: linear-gradient(270deg, #37393f 50%, rgba(255, 255, 255, 0));
}

[data-theme='dark'] .reports_carousel > [data-arrow='left'] {
  background: linear-gradient(90deg, #37393f 40%, rgba(255, 255, 255, 0));
}

[data-theme='dark'] .cross_team-info-tags > .cross_team-info-tags__icon--right {
  color: var(--user-setting-dropdown-color);
}
