@import url('assets/fonts/open-sans/fonts.css');

@keyframes slowVisibility {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 10px;
}

body {
  background-color: var(--background);
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans', monospace;
}

button {
  border: none;
  cursor: pointer;
  outline: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input {
  outline: none;
}

input:focus {
  border-color: #56ccf2;
}

.error {
  border: 1px solid red !important;
}

.fixed_right_corner {
  z-index: 20;
}

// @media (min-width: 1024px) {
//     html {
//         font-size: 12px;
//     }
// }

::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-scroll-thumb);
  border-radius: 3px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

[data-theme='dark'] {
  --background: #36393f;
  --bg-hover-timer-block: #52565f;
  --bg-hover-timer-block-item: #52565f;
  --bg-decimal-modal: #36393f;
  --text-primary: white;
  --text-secondary: grey;
  --text: #fff;
  --text-discount: #52565f;
  --box-shadow: none;
  --background-box-time-day: #40444b;
  --background-btn-time-day: #52565f;
  --color-day-header: #b9bbbe;
  --timer-arrow-down: '../../../assets/images/icons/arrow-down.svg';
  --border-bottom-timer-search: #4f4f4f;
  --border-bottom-search-component: 1px solid #52565f;
  --placeholder-color: #b9bbbe;
  --timer-placeholder_color: #e0e0e0;
  --timer-project-list: #40444b;
  --patch-timer-icon: #c1c0c0;
  --text-report-total-time: #bdbdbd;
  --bg-color-reports-button: #3b3b3b;
  --border-bottom-reports-projects: 1px solid rgba(189, 189, 189, 0.11);
  --reports-select-body: #3f3f3f;
  --color-secondary: #333;
  --border-bottom-task-header: 1px solid #bdbdbd;
  --border-bottom-project-filter: 1px solid #bdbdbd4d;
  --project-icon-path-color: #b9bbbe;
  --arrow-down-project-data: #e0e6ed;
  --invoices-bg-box: #40444b;
  --empty-color: #d3dce6;
  --bg-all-invoices-list: rgba(255, 255, 255, 0.06);
  --reference-text-color: #d3dce6;
  --bg-invoices-page-deteiled: #36393f;
  --invoices-deteiled-selected-data-path: #d3dce6;
  --bg-invoices-person-select-dropdown: #27292a;
  --bg-invoices-project-table-header: #292b2f;
  --bg-invoices-summary-table-row: #242424;
  --bg-overview-block: #353535;
  --border-bottom-overview-panel-header: 1px solid hsla(0, 0%, 100%, 0.1);
  --bg-biling-history-header: #272727;
  --color-input-user-setting: #d8d8d8;
  --user-setting-dropdown-list: #4f4f4f;
  --user-setting-title-color: #c9c9c9;
  --bg-user-setting-technology-tag: #e9e9e9;
  --user-setting-input-subtitle-color: #d9d9d9;
  --spiner-color: rgb(224, 224, 224);
  --bg-spiner-wrapper-overlay: rgba(51, 51, 51, 0.7);
  --bg-page-auth-page: #3b3b3b;
  --bg-hover-invoices-list-item: #52565f;
  --bg-modal-box: #373a40;
  --bg-modal-box-login: #FFFFFF;
  --bg-subscription-header: #36393f;
  --border-color-subscription: #52565f;
  --color-subscription-header: #979da6;
  --bg-switcher-subscription: #40444b;
  --bg-switcher-subscription-discount: #fff;
  --bg-card-plan: #40444b;
  --subscription-color-card-text: #fff;
  --bg-dropdown-switcher-team: #36393f;
  --border-color-team: #52565f;
  --bg-team-edit-user: #373a40;
  --bg-tooltip-tippy: #292b2f;
  --user-setting-color: #b9bbbe;
  --user-setting-dropdown-color: #fff;
  --bg-login: #2f3136;
  --border-login: #52565f;
  --bg-dropdown-resource-planning: #36393f;
  --color-resource-plaaning: #b9bbbe;
  --color-calendar-icon: #fff;
  --bg-dropdown-show-day: #fff;
  --bg-avatar-client: #52565f;
  --overview-color: #aeb5bd;
  --border-overview: 1px solid rgba(255, 255, 255, 0.1);
  --overview-color-text-button: #fff;
  --bg-header-billing-info: #52565f;
  --bg-leftBar: #2f3136;
  --color-scroll-thumb: #333;
  --color-scroll-thumb-board: #636363;
  --leftBar-box-shadow: rgba(0, 0, 0, 0.14);
  --leftBar-color: #fff;
  --leftBar-hover: #40444b;
  --leftBar-active-color: #fff;
  --bg-upgrade-banner: #36393f;
  --color-total-time-month: #b9bbbe;
  --timer-input-box-shadow: rgba(0, 0, 0, 0.15);
  --timer-box-shadow: black;
  --calendar-box-shadow: none;
  --bg-button-apply: #52565f;
  --bg-coupon-input: #36393f;
  --bg-active-project-for-rerports: #52565f;
  --reports-export-modal-shadow: black;
  --project-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  --bg-button-apply-active: #00b173;
  --bg-add-lane-btn: #00b173;
  --invoice-all-invoices-hover: #52565f;
  --bg-pagination-selected: rgba(255, 255, 255, 0.06);
  --invoices-detailed-border-input: none;
  --payment-confirm-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.6);
  --box-shadow-payment-info: 1px 2px 10px rgba(0, 0, 0, 0.2);
  --bg-resource-planning-weekend: #2f3136;
  --bg-resource-planning-item-user: #40444b;
  --bg-tag: #52565f;
  --bg-wraper-delete-plan: #24232b;
  --color-spiner: #e0e0e0;
  --color-chekbox: #ffff;
  --border-color: none;
  --second-background-sender-recipient: #24232b;
  --disabled:#828282;
  --bg-resource-planning-item-user-hover: #40444b;
  --border-color-resource-planning: none;
  --bg-hover-user-setting-list-item: #52565f;
  --bg-hover-arrow-left: #b9bbbe;
  --color-no-invoices: #bebbbb;
  --hover-calendar-item: #36393f;
  --hover-calendar-date: rgba(0, 0, 0, 0.08);
  --border-tippy-dark: #292b2f;
}

[data-theme='light'] {
  --background: #fafafc;
  --bg-hover-timer-block: #fafafc;
  --bg-hover-timer-block-item: #fafafc;
  --bg-decimal-modal: #fafafc;
  --text-primary: #52565f;
  --text-secondary: royalblue;
  --text: #52565f;
  --text-discount: #ffffff;
  --box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
  --background-box-time-day: #ffffff;
  --background-btn-time-day: #979da6;
  --color-day-header: #4f4f4f;
  --timer-arrow-down: '../../../assets/images/icons/arrow-down-gray.svg';
  --border-bottom-timer-search: #4f4f4f;
  --border-bottom-search-component: 1px solid #979da6;
  --placeholder-color: #4f4f4f;
  --timer-placeholder_color: #4f4f4f;
  --timer-project-list: #fff;
  --patch-timer-icon: black;
  --text-report-total-time: #4f4f4f;
  --bg-color-reports-button: #fff;
  --border-bottom-reports-projects: 1px solid #e6e9ed;
  --reports-select-body: #d8d8d8;
  --color-secondary: #fff;
  --border-bottom-task-header: 1px solid #e6e9ed;
  --border-bottom-project-filter: 1px solid #e6e9ed;
  --project-icon-path-color: black;
  --invoices-bg-box: #ffffff;
  --bg-all-invoices-list: #f5f5f5;
  --reference-text-color: #828282;
  --bg-invoices-page-deteiled: #fafafc;
  --invoices-deteiled-selected-data-path: #4f4f4f;
  --bg-invoices-person-select-dropdown: #fafafc;
  --bg-invoices-project-table-header: #fafafc;
  --bg-invoices-summary-table-row: #c9c9c9;
  --bg-tooltip-tippy: #fafafc;
  --bg-overview-block: #fff;
  --border-bottom-overview-panel-header: 1px solid hsla(0, 0%, 77%, 0.6);
  --bg-biling-history-header: #c9c9c9;
  --color-input-user-setting: #4f4f4f;
  --user-setting-dropdown-list: #d8d8d8;
  --user-setting-title-color: #4f4f4f;
  --bg-user-setting-technology-tag: #cecece;
  --user-setting-input-subtitle-color: #696969;
  --spiner-color: rgb(90, 90, 90);
  --bg-spiner-wrapper-overlay: rgba(229, 229, 229, 0.7);
  --bg-page-auth-page: #e5e5e5;
  --bg-hover-invoices-list-item: none;
  --bg-modal-box: #fff;
  --bg-modal-box-login: #fafafc;
  --bg-subscription-header: #fafafc;
  --border-color-subscription: #e6e9ed;
  --color-subscription-header: #36393f;
  --bg-switcher-subscription: #fff;
  --bg-switcher-subscription-discount: #40444b;
  --bg-card-plan: #fff;
  --subscription-color-card-text: #52565f;
  --bg-dropdown-switcher-team: #fff;
  --border-color-team: #e6e9ed;
  --bg-team-edit-user: #fff;
  --user-setting-color: #52565f;
  --user-setting-dropdown-color: #52565f;
  --bg-login: #fff;
  --border-login: #979da6;
  --color-resource-plaaning: #979da6;
  --color-calendar-icon: #979da6;
  --bg-dropdown-resource-planning: #fafafc;
  --bg-dropdown-show-day: #fafafc;
  --bg-avatar-client: #b9bbbe;
  --overview-color: #b9bbbe;
  --border-overview: 1px solid #e6e9ed;
  --overview-color-text-button: #00b173;
  --bg-header-billing-info: #fff;
  --bg-leftBar: #ffffff;
  --color-scroll-thumb: #979da6;
  --color-scroll-thumb-board: #979da6;
  --leftBar-box-shadow: rgba(0, 0, 0, 0.04);
  --leftBar-color: #52565f;
  --leftBar-hover: #e6e9ed;
  --leftBar-active-color: #373a40;
  --bg-upgrade-banner: #fafafc;
  --color-total-time-month: #52565f;
  --timer-input-box-shadow: none;
  --timer-box-shadow: none;
  --calendar-box-shadow: grey;
  --bg-button-apply: #e6e9ed;
  --bg-coupon-input: #e6e9ed;
  --bg-button-apply-active: #c5c7c9;
  --bg-add-lane-btn: #00b173;
  --bg-active-project-for-rerports: #e6e9ed;
  --reports-export-modal-shadow: rgba(0, 0, 0, 0.1);
  --project-box-shadow: none;
  --invoice-all-invoices-hover: #fff;
  --bg-pagination-selected: #e5e5e5;
  --invoices-detailed-border-input: 1px solid #e6e9ed;
  --payment-confirm-box-shadow: none;
  --box-shadow-payment-info: none;
  --bg-resource-planning-weekend: #fafcff;
  --bg-resource-planning-item-user: #e5e5e5;
  --bg-tag: #e6e9ed;
  --bg-wraper-delete-plan: none;
  --color-spiner: #52565f;
  --color-chekbox: #52565f;
  --border-color: 1px solid #e6e9ed;
  --second-background-sender-recipient: #fafafc;
  --disabled:#67518c;
  --bg-resource-planning-item-user-hover: #fafafc;
  --border-color-resource-planning: 1px solid #e6e9ed;
  --bg-hover-user-setting-list-item: #fafafc;
  --bg-hover-arrow-left: #979da6;
  --color-no-invoices: #979da6;
  --hover-calendar-item: #b9bbbe;
  --hover-calendar-date: rgba(0, 0, 0, 0.2);
}
