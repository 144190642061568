.dropdown {
  position: relative;
  -webkit-touch-callout: none;
  user-select: none;
  user-select: none;
  user-select: none;
  width: 160px;

  &__selected-item {
    align-items: center;
    background-color: var(--background-box-time-day);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 12px 15px;
  }

  &__selected-item-text {
    color: var(--text);
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__arrow {
    flex-shrink: 0;
    height: 18px;
    margin-left: 5px;
    width: 18px;

    path {
      stroke: var(--leftBar-color);
    }

    &--open {
      transform: rotate(-180deg);

      path {
        stroke: #00b173;
      }
    }
  }

  &__options-list {
    background-color: var(--background-box-time-day);
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--leftBar-color);
    display: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    left: 0;
    line-height: 19px;
    max-height: 300px;
    overflow-y: auto;
    padding: 4px;
    position: absolute;
    top: 47px;
    width: 100%;
    z-index: 5;

    &--open {
      display: block;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--bg-card-plan);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--bg-hover-invoices-list-item);
      border-radius: 4px;
    }
  }

  &__option {
    cursor: pointer;
    padding: 12px 11px;

    &:hover {
      color: var(--text);
      background-color: var(--bg-hover-invoices-list-item);
      border-radius: 4px;
    }
  }
}
