.error-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  
  .error-cats {
    position: relative;
    right: -43px;
    top: -305px;
    width: 193px;
    height: 216px;
    margin-bottom: 90px;
  }
  .error-text{
      position: relative;
      font-size: 500px;
      margin: 0px;
      font-weight: 800;
      color: #FFFFFF;
      margin-top: 162px;
      text-align: center;
  }
  .box-error-text{
      position: relative;
      width: 310px;
      margin-top: -330px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      h1{
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 49px;
        color: #FFFFFF;
      }
      p{
        margin-top: 0px;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        color: #B9BBBE;
      }
      .error-back-button{
        width: 180px;
        height: 44px;
        background: #00B173;
        border-radius: 8px;
        color: #fff;
        display: block;
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
        margin: 0 auto;
        padding: 12px;
        text-align: center;
        text-decoration: none;
      }
  }
  
  @media screen and (max-width: 1062px) {
    .error-cats {
      right: -28px;
      top: -190px;
      height: 135px;
      width: 135px;
      margin-bottom: 160px;


    }
    .error-text{
      font-size: 320px;
    }
  }
  @media screen and (max-width: 620px) {
    .error-text{
      font-size: 280px;
    }
    .error-cats {
      top: -186px;
    }
  }

  @media screen and (max-width: 553px) {
    .error-cats {
      top: -206px;
      height: 80px;
      right: -15px;
      width: 80px;
    }
  
    .error-text {
      font-size: 165px;
      margin-bottom: 95px;
      margin-top: 85px;
    }
  }