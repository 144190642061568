/* Open Sans @font-face kit */

/* BEGIN light */
@font-face {
  font-family: 'Open Sans';
  src: url('flight/opensans-light.eot');
  src: url('flight/opensans-light.eot?#iefix') format('embedded-opentype'),
       url('flight/opensans-light.woff') format('woff'),
       url('flight/opensans-light.ttf') format('truetype'),
       url('flight/opensans-light.svg#opensanslight') format('svg');
  font-weight: 300;
  font-style: normal;
}
/* END light */

/* BEGIN regular */
@font-face {
  font-family: 'Open Sans';
  src: url('fregular/opensans-regular.eot');
  src: url('fregular/opensans-regular.eot?#iefix') format('embedded-opentype'),
       url('fregular/opensans-regular.woff') format('woff'),
       url('fregular/opensans-regular.ttf') format('truetype'),
       url('fregular/opensans-regular.svg#opensansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* END regular */

/* BEGIN semibold */
@font-face {
  font-family: 'Open Sans';
  src: url('fsemibold/opensans-semibold.eot');
  src: url('fsemibold/opensans-semibold.eot?#iefix') format('embedded-opentype'),
       url('fsemibold/opensans-semibold.woff') format('woff'),
       url('fsemibold/opensans-semibold.ttf') format('truetype'),
       url('fsemibold/opensans-semibold.svg#opensanssemibold') format('svg');
  font-weight: 600;
  font-style: normal;
}
/* END semibold */

/* BEGIN bold */
@font-face {
  font-family: 'Open Sans';
  src: url('fbold/opensans-bold.eot');
  src: url('fbold/opensans-bold.eot?#iefix') format('embedded-opentype'),
       url('fbold/opensans-bold.woff') format('woff'),
       url('fbold/opensans-bold.ttf') format('truetype'),
       url('fbold/opensans-bold.svg#opensansbold') format('svg');
  font-weight: bold;
  font-style: normal;
}
/* END bold */

/* BEGIN extrabold */
@font-face {
  font-family: 'Open Sans';
  src: url('fextrabold/opensans-extrabold.eot');
  src: url('fextrabold/opensans-extrabold.eot?#iefix') format('embedded-opentype'),
       url('fextrabold/opensans-extrabold.woff') format('woff'),
       url('fextrabold/opensans-extrabold.ttf') format('truetype'),
       url('fextrabold/opensans-extrabold.svg#opensansextrabold') format('svg');
  font-weight: 800;
  font-style: normal;
}
/* END extrabold */
