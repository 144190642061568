.cat-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  &__animation {
    width: 640px;
  }
}

.animation-cat {
  // height: 250px;
  position: relative;
  // right: -30px;
  // width: 230px;
}
.cat-light-theme {
  position: relative;
  background: url('../../../../assets/images/error_cat.svg') no-repeat center;
  top: -5px;
  right: -32px;
  width: 200px;
  height: 280px;
}

.animation-cat-comment {
  color: var(--text);
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  margin-top: -60px;
  position: relative;
  text-align: center;
}

@media screen and (max-width: 1062px) {
  // .animation-cat {
  //   right: -40px;
  // }
}
@media screen and (max-width: 680px) {
  .cat-wrapper__animation {
    width: 396px;
  }
}
@media screen and (max-width: 414px) {
  // .animation-cat {
  //   height: 150px;
  //   right: -20px;
  //   width: 150px;
  // }

  .cat-wrapper__animation {
    width: 348px;
  }

  .animation-cat-comment {
    font-size: 16px;
    margin-top: -70px;
    top: 40px;
  }
}
